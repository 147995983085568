
import { defineComponent, onMounted, reactive, toRefs } from 'vue'

import { ElMessageBox } from 'element-plus'
import { wuRequest } from '@/service'

import { url } from '@/service/config'

//import HelloWorld from '@/components/HelloWorld.vue' // @ is an alias to /src

export default defineComponent({
    components: {},
    setup() {
        let t = reactive<any>({
            data: {},
            url
        })

        onMounted(async () => {
            // 保持菜单更新一直在顶
            document.body.scrollTop = document.documentElement.scrollTop = 0
            let res = await wuRequest.get({
                url: '/custom-case'
            })
            if (res.data.code === 10001) {
                t.data = res.data.data.data
            } else {
                ElMessageBox.alert('数据请求错误，请稍后重试', '数据异常', {
                    confirmButtonText: 'OK'
                })
                return false
            }
        })

        return { ...toRefs(t) }
    }
})
